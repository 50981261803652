import {
  formatPrice,
  formatDate,
  formatDateOnly,
} from "@/assets/utils/common.utils.js";
import { endBefore, limit, limitToLast, startAfter } from "firebase/firestore";
import { mapGetters, mapMutations } from "vuex";
import { clientTypes } from "../assets/constants/client.constants.js";
import _ from "lodash";
export default {
  data() {
    return {
      clientTypes,
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "roles",
      "userEmail",
      "filters",
      "paymentMethods",
      "uid",
      "getUpdatedBy",
      "clientStatusTypes",
      "affiliates",
      "getClientStatusColor",
      "currentClient",
      "getAffiliateName",
      "getRoleName",
    ]),
    isSendFaxAdmin() {
      return (
        _.get(this.user, "permissions.send-faxes.wizard_type", "") == "tier-1"
      );
    },
    canPayout() {
      return _.get(this.user, "permissions.affiliates.payout", false) == true;
    },
    canChargeClient() {
      return _.get(this.user, "permissions.customers.charge", false) == true;
    },
  },
  methods: {
    ...mapMutations(["updateFilter"]),
    formatDate,
    formatPrice,
    formatDateOnly,
    getClientType({ client_type }) {
      let type = clientTypes.find((i) => i.value == client_type);
      return type ? type.name : "";
    },
    hasPermission(area, type) {
      let permissions = this.user.permissions || {};
      let permission = permissions[area] || {};
      return permission[type] == true;
    },
    hasEdit(area) {
      let permissions = this.user.permissions || {};
      let permission = permissions[area] || {};
      return permission.edit == true;
    },
    hasRead(area) {
      let permissions = this.user.permissions || {};
      let permission = permissions[area] || {};
      return permission.view == true;
    },
    hasDelete(area) {
      let permissions = this.user.permissions || {};
      let permission = permissions[area] || {};
      return permission.delete == true;
    },
    handleError(error) {
      const vm = this;
      let message = error;
      console.log(error.code);
      if (
        error.code == "failed-precondition" &&
        error?.message.indexOf("You can create it here:") != -1
      ) {
        window.open(
          error.message.split("You can create it here:")[1],
          "_blank"
        );
        console.log(error.message.split("You can create it here:")[1]);
      }
      if (error.message) message = error.message;
      vm.isLoading = false;
      vm.loading = false;
      this.$loader.hide();
      vm.notifyError(message);
      throw error;
    },
    notifySuccess(message) {
      this.$message.info({
        message,
        type: "success",
        iconImg: require("../assets/images/check.png"),
        position: "bottom-center",
        className: "bg-success",
        width: "1000px",
        showClose: true,
      });
    },
    notifyError(message) {
      this.$message.info({
        message,
        iconImg: require("../assets/images/cancel.png"),
        position: "bottom-center",
        type: "error",
        width: "1000px",
        // duration: 1000000,
        showClose: true,
        className: "bg-error",
      });
    },
    isLastPage(options) {
      let { itemsPerPage, page } = options;
      return page != 1 && parseInt(this.total / itemsPerPage) + 1 == page;
    },
    getPaginationQuery(options) {
      let list = [];
      const vm = this;
      let { page, itemsPerPage } = options;
      let { currentPage, lastVisible, firstVisible, isLastPage } = vm;
      if (page == 1) {
        list.push(limit(itemsPerPage));
      }
      if (page != 1 && page > currentPage) {
        if (isLastPage(options)) {
          let count = vm.total % itemsPerPage;
          if (count == 0) count = itemsPerPage;
          list.push(limitToLast(count));
        } else {
          list.push(startAfter(lastVisible));
          list.push(limit(itemsPerPage));
        }
      }
      if (page != 1 && page < currentPage) {
        list.push(endBefore(firstVisible));
        list.push(limitToLast(itemsPerPage));
      }
      return list;
    },
    getAffiliateTransactionKey(item, id) {
      let { affiliate_id, affiliate_id_2, affiliate_id_3 } = item;
      let affiliate_key = "";
      if (affiliate_id == id) affiliate_key = "affiliate_1";
      if (affiliate_id_2 == id) affiliate_key = "affiliate_2";
      if (affiliate_id_3 == id) affiliate_key = "affiliate_3";
      return affiliate_key;
    },
    affiliateCommissionRate(item, id) {
      let affiliate_key = this.getAffiliateTransactionKey(item, id);
      return parseFloat(item[`${affiliate_key}_commission_rate`] || 0);
    },
    affiliatePayoutMethod(item, id) {
      let affiliate_key = this.getAffiliateTransactionKey(item, id);
      return item[`${affiliate_key}_payment_method`] || "";
    },
    affiliateCommission(item, id) {
      let affiliate_key = this.getAffiliateTransactionKey(item, id);
      return parseFloat(item[`${affiliate_key}_commission`] || 0);
    },
    commissionRate(item) {
      let affiliates = 0;
      let commission = 0;
      if (item.affiliate_1_commission_rate) {
        commission += parseFloat(item.affiliate_1_commission_rate);
        affiliates++;
      }
      if (item.affiliate_2_commission_rate) {
        commission += parseFloat(item.affiliate_2_commission_rate);
        affiliates++;
      }
      if (item.affiliate_3_commission_rate) {
        commission += parseFloat(item.affiliate_3_commission_rate);
        affiliates++;
      }
      return `${(commission / affiliates).toFixed(0)}%`;
    },
  },
};
