import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
var firebaseConfig = {
  apiKey: "AIzaSyAVv7-RWFpbltAINCTNFADAvxIYejV2Rnc",
  authDomain: "bcsapp-291716.firebaseapp.com",
  databaseURL: "https://bcsapp-291716.firebaseio.com",
  projectId: "bcsapp-291716",
  storageBucket: "bcsapp-291716.appspot.com",
  messagingSenderId: "237175133520",
  appId: "1:237175133520:web:ed1624566a514f64828855",
};
// eslint-disable-next-line no-unused-vars
var stagingConfig = {
  apiKey: "AIzaSyBfHACJBj7BQiViP0oRxQeB4jd2ygS-9p8",
  authDomain: "bcsappstaging.firebaseapp.com",
  projectId: "bcsappstaging",
  storageBucket: "bcsappstaging.appspot.com",
  messagingSenderId: "420975993366",
  appId: "1:420975993366:web:1d17364d484ac75c648b37",
};
const isLocal = location.hostname == "localhost";
if (!["bcsapp.web.app", "localhost"].includes(location.hostname)) {
  firebaseConfig = stagingConfig;
}
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

const colUsers = collection(db, "users");
const colClientStatusTypes = collection(db, "client_status_types");
const colAffiliates = collection(db, "affiliates");
const colClients = collection(db, "customers");
const colSettings = collection(db, "settings");
const colRoles = collection(db, "roles");
const colPaymentMethods = collection(db, "payment_methods");
const colClientPaymentMethods = collection(db, "client_payment_methods");
const colFaxSchedules = collection(db, "fax_schedules");
const colFaxTemplates = collection(db, "fax_templates");
const colFaxes = collection(db, "faxes");
const colFaxRecipients = collection(db, "fax_recipients");
const colTransactions = collection(db, "transactions");
const colCommissions = collection(db, "commissions");
const colCreditReports = collection(db, "credit_reports");
const colSavedCreditReports = collection(db, "saved_credit_reports");
const colSavedLetters = collection(db, "saved_letters");
const colCreditScores = collection(db, "credit_scores");
const colDocuments = collection(db, "documents");
const colCustomerDocuments = collection(db, "customer_documents");
const colTeamSchedules = collection(db, "team_schedules");
const colClientAgreements = collection(db, "client_agreements");
const colCreditReportLogs = collection(db, "credit_report_logs");
const storage = getStorage(app);

if (isLocal) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
export {
  auth,
  colUsers,
  db,
  functions,
  colAffiliates,
  colClients,
  colSettings,
  colClientStatusTypes,
  colRoles,
  colPaymentMethods,
  colFaxSchedules,
  colFaxes,
  colCommissions,
  colFaxRecipients,
  colFaxTemplates,
  colTransactions,
  isLocal,
  colCreditReports,
  colCreditScores,
  colDocuments,
  colCustomerDocuments,
  colTeamSchedules,
  colClientAgreements,
  colSavedCreditReports,
  colSavedLetters,
  colCreditReportLogs,
  colClientPaymentMethods,
  storage,
};
